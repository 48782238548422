export const anexityCommentsData = [
  {
    img: 'person0.svg',
    imgAlt: 'Портрет девушки с розовыми волосами на зеленом фоне',
    name: 'Алина',
    age: '26 лет',
    title: 'Уже после нескольких сеансов почувствовала улучшение',
    desc: 'YouTalk стал для меня настоящей находкой. Я смогла найти специалиста, который работает с тревожностью, и уже после нескольких сеансов почувствовала улучшение.'
  },
  {
    img: 'person1.svg',
    imgAlt: 'Портрет девушки в очках с черными волосами на красном фоне',
    name: 'София',
    age: '27 лет',
    title:
      'Консультант предложил мне несколько специалистов, соответствующих моим критериям',
    desc: 'Я искала психолога для работы с тревожностью. Консультант предложил мне несколько специалистов, соответствующих моим критериям, и я смогла ознакомится с их анкетами. Это очень помогло мне сделать выбор, так как я могла оценить их подход. Отличный сервис, рекомендую 🥰'
  },
  {
    img: 'person6.svg',
    imgAlt: 'Портрет женщины со светлыми волосами на синем фоне',
    name: 'Любовь',
    age: '28 лет',
    title: 'Мои переживания - это нормально и я имею на них право',
    desc: 'Долго не обращалась за помощью, так как боялась, что скажут, что мои проблемы ерунда. Да и сама думала, что у меня все еще недостаточно плохо, чтобы идти к специалисту. Но девушка-консультант из службы поддержки поддержала меня и сказала, что мои переживания - это нормально и я имею на них право. Она подобрала для меня замечательного, чуткого специалиста, с которым я смогла поделиться своими проблемами. Хочу еще раз сказать большое спасибо!'
  },
  {
    img: 'person7.svg',
    imgAlt: 'Портрет темноволосой девушки на желтом фоне',
    name: 'Ольга',
    age: '25 лет',
    title: 'Впервые за долгое время я почувствовала, что меня понимают.',
    desc: 'Хотела бы поделиться своим опытом психотерапевтической работы с психологом Youtalk. Обратилась в клиентский сервис, когда не могла определиться с выбором специалиста. И не прогадала. Мне подобрали очень крутого психолога, явно специалиста своего дела. У нас прошло не так много сессий с моим психологом, но я уже заметила значительные изменения в своем состоянии. Мы разобрали причины моей тревожности и разобрали, как я могу с ней справляться. Особенно понравилось, что на сессиях не было чувства давления, о котором я переживала. Только поддержка и забота. Впервые за долгое время я почувствовала, что меня понимают. А это правда многого стоит'
  },
  {
    img: 'person5.svg',
    imgAlt:
      'Портрет бородатого кудрявого мужчины с черными волосами на светло-оранжевом фоне',
    name: 'Дмитрий',
    age: '30 лет',
    title: 'YouTalk гарантирует защиту данных клиентов',
    desc: 'У меня были опасения по поводу конфиденциальности, но YouTalk гарантирует защиту данных клиентов. Это важно для меня.'
  },
  {
    img: 'person8.svg',
    imgAlt: 'Портрет темноволосой девушки с длинными волосами на зеленом фоне',
    name: 'Виктория',
    age: '37 лет',
    title: 'Главное - наладила отношения с самой собой',
    desc: 'Работаю в этом сервисе с психологом уже очень давно. За это время многие запросы решила, главное - наладила отношения с самой собой. Продолжаю терапию и советую всем своим знакомым ❤️'
  }
]

import App from '../components/App'
import Footer from '../components/Footer'
import Header from '../components/Header'
import React from 'react'
import SEO from '../atoms/SEO'
import styled from 'styled-components'
import { AboutPsychologistHelp } from '../organisms/AboutPsychologistHelp'
import { AnexityArticles } from '../organisms/AnexityArticles'
import { AnexityPsychologists } from '../organisms/AnexityPsychologists'
import { AnexityTechnique } from '../organisms/AnexityTechnique'
import { AnexityTest } from '../organisms/AnexityTest'
import { BreadCrumbs } from '../molecules/BreadCrumbs'
import { CommentsSection } from '../organisms/CommentsSection'
import { DealWithAnexity } from '../organisms/DealWithAnexity'
import { HeaderQuestions } from '../organisms/HeaderQuestions'
import { HowItSection } from '../organisms/HowItSection'
import { LifeDestruction } from '../organisms/LifeDestruction'
import { ProductMicromarkupComponent } from '../atoms/SEO/Micromarkup/Product'
import { QuestionListAnxiety } from '../molecules/QuestionListAnxiety'
import { ReduceAnxiety } from '../organisms/ReduceAnxiety'
import { RightChoiseTariffSection } from '../organisms/RightChoiseTariffSection'
import { anexityCommentsData } from '../organisms/AnexityComments/comments'
import { color } from '../styles/vars/colors'
import { graphql } from 'gatsby'
import { pick } from 'rambda'
import { size } from '../constants'

const Page = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 76px repeat(2, auto);
  min-height: calc(var(--vh, 1vh) * 100);
  background: #e6f0fd;

  @media (max-width: ${size.xs}) {
    grid-template-rows: 56px repeat(2, auto);
  }
`

const Main = styled.div`
  & ${RightChoiseTariffSection} {
    background: ${color.background};
    padding-top: 71px;
    padding-bottom: 115px;

    @media (max-width: ${size.lg}) {
      padding-top: 31px;
      padding-bottom: 119px;
    }

    @media (max-width: ${size.md}) {
      padding-top: 24px;
      padding-bottom: 69px;
    }

    @media (max-width: ${size.sm}) {
      padding-top: 26px;
      padding-bottom: 44px;
    }

    @media (max-width: ${size.xs}) {
      padding-top: 16px;
      padding-bottom: 32px;
    }
  }

  & ${HowItSection} {
    @media (max-width: ${size.sm}) {
      padding-top: 40px !important;
    }
  }

  & section {
    max-width: 100vw;
  }
`

// eslint-disable-next-line max-lines-per-function
const Trevojnost = ({ data }) => (
  <App>
    <SEO
      description="Помощь в борьбе с тревожностью онлайн ✔Более 280 психологов ✔Онлайн сессия от 2000 ₽ ✔Анонимный прием ➨Быстрый подбор специалиста под вашу проблему"
      title="Как справиться с тревожностью — онлайн консультация психолога по борьбе с тревогой  | YouTalk"
    >
      <ProductMicromarkupComponent name="Как справиться с тревожностью? Психологическая помощь при тревоге" />
    </SEO>
    <Page>
      <Header />
      <BreadCrumbs />
      <Main>
        <HeaderQuestions />
        <LifeDestruction />
        <AnexityTest />
        <DealWithAnexity />
        <ReduceAnxiety />
        <AboutPsychologistHelp />
        <AnexityTechnique />
        <AnexityPsychologists
          data={pick(['psychologists', 'workAreas'], data)}
        />
        <HowItSection />
        <CommentsSection commentsCard={anexityCommentsData} />
        <QuestionListAnxiety />
        <AnexityArticles data={data.articles} />
        <RightChoiseTariffSection />
      </Main>
      <Footer />
    </Page>
  </App>
)

export default Trevojnost

export const query = graphql`
  query Anexity {
    articles: allMdx(
      sort: { order: DESC, fields: frontmatter___created_at }
      filter: {
        fileAbsolutePath: { regex: "/blog/" }
        frontmatter: { tags: { elemMatch: { title: { eq: "тревога" } } } }
      }
    ) {
      totalCount
      nodes {
        frontmatter {
          announcement
          created_at(formatString: "DD.MM.YYYY")
          color
          title
          author
          tags {
            title
          }
          img {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        slug
        id
      }
    }
    workAreas: allWorkAreasData(
      filter: {
        tag: { in: ["Тревога, волнение", "Страхи, фобии", "Панические атаки"] }
      }
    ) {
      edges {
        node {
          id
        }
      }
    }
    psychologists: allPsychologistsData(
      filter: {
        nearest_slot: { ne: null }
        visibility: { eq: "OPEN" }
        work_areas: {
          elemMatch: {
            tag: {
              in: ["Тревога, волнение", "Страхи, фобии", "Панические атаки"]
            }
          }
        }
      }
    ) {
      edges {
        node {
          id
          _id
          is_active
          about
          approaches {
            _id
            id
            name
          }
          audio
          birthday_date
          educations {
            speciality
            title
            type
            yearEnd
            yearStart
          }
          english
          family
          surname
          features
          gender
          individual
          messages
          is_active
          name
          non_working_areas {
            _id
            id
            tag
          }
          photoUrl
          professions {
            profession_name
          }
          self_appointment
          sync_messages
          start_of_practice
          video
          work_areas {
            _id
            group {
              name
              type
            }
            id
            tag
          }
        }
      }
      totalCount
    }
  }
`
